import { Polyline, CircleMarker, Popup } from "react-leaflet";

import { Map } from "./Map";

import { StopsInBounds } from "../components/StopsInBounds";
import { useSelectedStore } from "../store/useSelectedStore";
import { stores } from "../store/stores";
import * as selectors from "../store/selectors";
import { useVehiclesPositions } from "../utils/hooks/useVehiclesPositions";
import { getVehiclePositionMarker } from "../utils/getVehiclePositionMarker";

export function MapView() {
  const vehiclesPositions = useVehiclesPositions();
  const vehiclesPositionsMarkers = vehiclesPositions?.map(getVehiclePositionMarker);
  const { track, trackStops, userLocation } = useSelectedStore(stores.map, [
    selectors.track,
    selectors.trackStops,
    selectors.userLocation
  ]);

  const userLocationMarker = userLocation?.lat && (
    <CircleMarker fillColor="#4299e1" radius={7} fillOpacity={0} center={[userLocation.lat, userLocation.lng]} color='#4299e1'>
      <CircleMarker radius={2} fillOpacity={1} fillColor='#4299e1' center={[userLocation.lat, userLocation.lng]} />
    </CircleMarker>
  );

  const markers = trackStops.map((ts, ind) => (
    <CircleMarker fillColor="teal" fillOpacity={0.75} key={ind} zIndex={10} color='black' center={[ts.lat, ts.lng]} radius={5}>
      <Popup>{ts.name}</Popup>
    </CircleMarker>
  ));

  const trackLine = <Polyline pathOptions={{color: 'teal'}} positions={track}/>;
  
  return (
    <Map>
      <StopsInBounds />
      {userLocationMarker}
      {markers}
      {trackLine}
      {vehiclesPositionsMarkers}
    </Map>
  );
}
