import { SimpleGrid, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { IoBus } from 'react-icons/io5';
import { IoLocationOutline } from 'react-icons/io5';

import { MenuGroup } from "./MenuGroup";
// import { RouteSearch } from "./RouteSearch";
 
export function HomeNavigation() {
  const { t } = useTranslation();

  return (
    <Box w='100%' overflow='scroll'>
      <SimpleGrid spacing={3}>
        {/* <RouteSearch
          searchText={{
            from: t('stop.search.from'),
            to: t('stop.search.to'),
          }}
        /> */}
        <Box p='0 1rem'>
          <Link to='routes' w='100%'>
            <MenuGroup
              p={2}
              bgColor='teal.500'
              borderRadius='0.5rem'
              w='100%'
              icon={IoBus}
              text={t('routes')}
              color='white'
            />
          </Link>
        </Box>
        <Box p='0 1rem'>
          <Link to='stops' w='100%'>
            <MenuGroup
              p={2}
              bgColor='teal.500'
              borderRadius='0.5rem'
              w='100%'
              icon={IoLocationOutline}
              text={t('stops')}
              color='white'
            />
          </Link>
        </Box>
      </SimpleGrid>
    </Box>
  );
}