import { Tabs, TabList, Tab, TabPanels, TabPanel, List, Box, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

import { Loader } from "./Loader";

export function DirectionStopDepartures({
  timesIsLoading,
  todayDepartures,
  departures,
  departuresDaysTypes,
}) {
  const { t } = useTranslation();

  return (
    <Tabs w='100%' isLazy variant='line' colorScheme='teal' display='flex' flexDir='column'>
      <TabList>
        <Tab>{t('days.today')}</Tab>
        {departuresDaysTypes}
      </TabList>
      <TabPanels overflowY='scroll'>
        <TabPanel p={0}>
          <Box w='100%'>
            <Text
              p='0.5rem 1rem'
              bgColor='gray.50'
              fontSize='sm'
            >
              {t('stop.departures.schedule')}
            </Text>
              {
                timesIsLoading
                ? <Loader size='md' h='50px' />
                : todayDepartures?.length
                ? <List>{todayDepartures}</List>
                : <Text p='1rem' fontStyle='italic' fontSize='sm'>{t('stop.departures.schedule.none')}</Text>
              }
          </Box>
        </TabPanel>
        {departures}
      </TabPanels>
    </Tabs>
  );
}