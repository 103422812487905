import {useCallback, useState, useMemo, useEffect} from "react";
import { useLocation } from "react-router-dom";
import { useMapEvent } from 'react-leaflet';

import * as selectors from "../store/selectors";
import {useBoundsStopsQuery} from "../services/api/queries";
import {useSelectedStore} from "../store/useSelectedStore";
import {stores} from "../store/stores";
import {getStopMarker} from "../utils/getStopMarker";

export function StopsInBounds() {
  const location = useLocation();
  const { userLocation, map } = useSelectedStore(stores.map, [selectors.userLocation, selectors.map]);
  const [bounds, setBounds] = useState(null);
  const { data: stops } = useBoundsStopsQuery(bounds);
  const [stopsState, setStopsState] = useState(stops);
  useEffect(() => {
    if (map && map.getZoom() < 15) {
      setStopsState(undefined);
    } else if (stops) {
      setStopsState(stops);
    }
  }, [map, stops]);
  const isUserLocated = useMemo(() => userLocation?.lat && userLocation?.lng, [userLocation?.lat, userLocation?.lng]);
  const updateMapBounds = useCallback(() => {
    if (location.pathname === '/' || location.pathname.includes('/stops') || isUserLocated) {
      if (map.getZoom() >= 15) {
        const {_southWest, _northEast} = map.getBounds();
        const data = [
          _southWest.lat, _southWest.lng,
          _northEast.lat, _northEast.lng
        ];
        setBounds(data.join(','));
      } else {
        setBounds(null);
      }
    } else {
      setBounds(null);
    }
  }, [map, location, isUserLocated])

  useMapEvent('moveend', updateMapBounds);

  return useMemo(() => stopsState?.map(getStopMarker), [stopsState]);
}
