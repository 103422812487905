import { useEffect } from 'react';
import { useParams, Link, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SimpleGrid, Tab, TabPanel, List } from '@chakra-ui/react'

import { BusNumberBlock } from "./BusNumberBlock";
import { Departure } from './Departure';
import { DirectionStopDepartures } from './DirectionStopDepartures';
import { Trip } from './Trip';

import { useSelectedStore } from '../store/useSelectedStore';
import { stores } from '../store/stores';
import * as selectors from "../store/selectors";

import { useDirectionStopsQuery, useRealTimeStopDeparturesQuery, useStopQuery } from '../services/api/queries';
import { getDayOfWeek, getDepartureTime, timeToInt } from '../utils/datesAndTimes';
import { useRehydrateRoute } from '../utils/hooks/useRehydrateRoute';

const dayOfWeek = getDayOfWeek();

export function DirectionStop() {
  const { t } = useTranslation();
  const { directionId, stopId } = useParams();
  const { data: directionStops, isLoading: scheduleTimesIsLoading } = useDirectionStopsQuery(directionId, stopId);
  const { data: realTimeData, isLoading: realTimeDataIsLoading } = useRealTimeStopDeparturesQuery(directionId, stopId);
  const { data: stop, isLoading } = useStopQuery(stopId);
  const { directionEnd, routeNumber, setSelectedStop } = useSelectedStore(stores.route, [
    selectors.directionEnd,
    selectors.routeNumber,
    selectors.setSelectedStop,
  ]);
  useRehydrateRoute();

  useEffect(() => {
    if (stop) {
      setSelectedStop(stop);
    }
  }, [stop, setSelectedStop]);

  const getDepartureElement = (item, ind, arr, showPredicted = false) => {
    let departureTime;
    let predicted = false;

    if (item.predictedTime && showPredicted) {
      departureTime = getDepartureTime(item.predictedTime);
      predicted = true;
    } else {
      departureTime = getDepartureTime(item.departureTime, { renderScheduleValue: true } );
    }

    return (
      <Link key={`${item.tripId}.${departureTime}`} to={`/routes/${item.routeId}/direction/${item.directionId}/stop/${item.stopId}/trip/${item.tripId}`}>
        <Departure departureTime={departureTime} predicted={predicted} stopName={t('departure.direction', { towards: item.towards })} />
      </Link>
    );
  };

  const getTodayDepartureElement = (item, ind, arr) => {
    return getDepartureElement(item, ind, arr, true);
  };
  
  let todayDepartures = directionStops?.find(i => i.days.includes(dayOfWeek))?.departures;
  realTimeData?.forEach(i => {
    const todayDeparture = todayDepartures?.find(j => j.tripId === i.tripId);
    if (todayDeparture) {
      todayDeparture.predictedTime = i.predictedTime;
    }
  });

  todayDepartures = todayDepartures
    ?.filter(i => {
      const time = i.predictedTime || i.departureTime;
      return timeToInt(time) >= timeToInt(new Date());
    })
    .map(getTodayDepartureElement);

  const departuresDaysTypes = directionStops?.map(daysType => {
    const type = daysType.days;
    let title = '';
    switch (type) {
      case '12345':
        title = t('days.workdays');
        break;

      case '67':
        title = t('days.weekend');
        break;

      case '6':
        title = t('days.saturday');
        break;

      case '7':
        title = t('days.sunday');
        break;

      default:
        title = type.split('').map(day => t(`days.${day}`)).join(' ');
    }
    
    return <Tab key={type}>{title}</Tab>;
  })

  const departures = directionStops?.map(daysType => {
    return <TabPanel p={0} key={daysType.days}><List>{daysType.departures.map(getDepartureElement)}</List></TabPanel>;
  })

  return (
    <SimpleGrid autoRows='auto minmax(100px, 1fr)' w='100%' overflowY='scroll'>
      <BusNumberBlock
        isLoading={isLoading}
        number={routeNumber}
        title={stop?.name}
        info={t('route.directionEnd', { directionEnd })}
      />
      <Routes>
        <Route
          path='/'
          element={<DirectionStopDepartures
            timesIsLoading={scheduleTimesIsLoading || realTimeDataIsLoading}
            todayDepartures={todayDepartures}
            departures={departures}
            departuresDaysTypes={departuresDaysTypes}
          />}
        />
        <Route path='trip/:tripId' element={<Trip />} />
      </Routes>
    </SimpleGrid>
  );
}
