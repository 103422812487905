import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useSelectedStore } from '../../store/useSelectedStore';
import { stores } from '../../store/stores';
import * as selectors from "../../store/selectors";
import { useRouteQuery } from '../../services/api/queries';

export function useRehydrateRoute() {
  const routerParams = useParams();

  const {
    directionId,
    setRouteDirection,
  } = useSelectedStore(
    stores.route,
    [selectors.routeId, selectors.setRouteDirection]
  );
  
  const { data } = useRouteQuery(routerParams.routeId);

  useEffect(() => {
    if (routerParams.directionId && routerParams.directionId !== directionId && data) {
      const direction = data.find(i => i.directionId === parseInt(routerParams.directionId, 10));
      if (direction) {
        setRouteDirection(direction);
      }
    }
  }, [data, directionId, routerParams, setRouteDirection]);
}
