import { Flex } from "@chakra-ui/react";

export function RealTimeIndicator(props) {
  return (
    <Flex w='20px' h='20px' {...props}>
      <svg id="radar-circle" style={{ width: '20px', height: '20px'}}>
        <circle cx="50%" cy="50%" r="0" fillOpacity="0" stroke="#3182ce" strokeWidth="1px" strokeOpacity="1">
          <animate attributeName="r" from="0" to="9" dur="3s" repeatCount="indefinite" />
          <animate attributeName="stroke-opacity" from="1" to="0" dur="3s" repeatCount="indefinite"></animate>
        </circle>
        
        <circle cx="50%" cy="50%" r="0" fillOpacity="0" stroke="#3182ce" strokeWidth="1px" strokeOpacity="1">
          <animate attributeName="r" from="0" to="9" dur="3s" repeatCount="indefinite" begin="0.75s" />
          <animate attributeName="stroke-opacity" from="1" to="0" dur="3s" repeatCount="indefinite" begin="0.75s"></animate>
        </circle>
        
        <circle cx="50%" cy="50%" r="0" fillOpacity="0" stroke="#3182ce" strokeWidth="1px" strokeOpacity="1">
          <animate attributeName="r" from="0" to="9" dur="3s" repeatCount="indefinite" begin="1.5s" />
          <animate attributeName="stroke-opacity" from="1" to="0" dur="3s" repeatCount="indefinite" begin="1.5s"></animate>
        </circle>
        
        <circle cx="50%" cy="50%" r="1" fill="#3182ce" stroke="#3182ce"></circle>
      </svg>
    </Flex>
  );
}
