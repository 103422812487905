import { Flex, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { BusRouteSelection } from "./BusRouteSelection";
import { Loader } from "./Loader";
import { RealTimeIndicator } from "./RealTimeIndicator";
import { StopListItem } from "./StopListItem";

import { useStopQuery } from "../services/api/queries";
import { getDepartureTime } from "../utils/datesAndTimes";
import { useClearRouteDirection } from "../utils/hooks/useClearRouteDirection";

export function Stop(props) {
  useClearRouteDirection();

  const { t } = useTranslation();
  const params = useParams();
  const { data, isLoading, isError } = useStopQuery(params.stopId);
  const stopRoutes = data?.routes?.map((i, ind) => {
    const route = props.routes.data?.find(j => j.id === i.id);
    const routeName = route?.name.split('-').pop();
    const href = `/routes/${route?.id}`;
    const result = {
      id: route?.id,
      number: route?.number,
      name: routeName,
    };

    const departureTimes = data?.departures?.[i.number]?.reduce((result, departure) => {
      const todayDeparture = !departure?.id.includes('nd');

      if (departure?.predictedTime) {
        const departureTime = getDepartureTime(departure.predictedTime.time);
        result.push({today: todayDeparture, time: departureTime, predicted: true, raw: departure.predictedTime.time });
      } else {
        const departureTime = getDepartureTime(departure?.time, { tomorrow: !todayDeparture});
        result.push({ today: todayDeparture, time: departureTime, raw: departure?.time });
      }
      return result;
    }, []);

    const [closestDepartureTime, ...restDeparturesTimes] = departureTimes || [];
    return {
      nextDeparture: closestDepartureTime?.today ? closestDepartureTime?.raw : `9${closestDepartureTime?.raw}`,
      el: (
        <BusRouteSelection key={ind} route={result} href={href} borderTop='1px solid' borderColor='gray.200'>
          <Flex ml='auto' direction='column' width='100px'>
            <Flex justifyContent='end' alignItems='center'>
              { closestDepartureTime?.today && closestDepartureTime?.predicted ? <RealTimeIndicator /> : null }
              <Text fontSize='15px' color={closestDepartureTime?.today ? 'initial' : 'gray.500'}>
                {closestDepartureTime?.time ?? null}
              </Text>
            </Flex>
            <Flex justifyContent='end'>
              { restDeparturesTimes?.map((d, ind) => <Text ml='5px' fontSize='13px' color={d.today ? 'initial' : 'gray.500'} key={ind}>{d.time}</Text>) }
            </Flex>
          </Flex>
        </BusRouteSelection>
      )
    }
  });

  const stopDepartures = stopRoutes?.sort((a, b) => {
    if (a.nextDeparture > b.nextDeparture) {
      return 1;
    }
    return -1;
  }).map(i => i.el);

  if (isError) {
    return <Flex>{t('error')}</Flex>
  }

  return (
    <VStack w='100%' alignItems='start' overflowY='scroll'>
      {isLoading ? <Loader /> : <StopListItem {...data} isLink={false} />}
      <Flex direction='column' width='100%'>
        {stopDepartures}
      </Flex>
    </VStack>
  );
}
