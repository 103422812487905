import shallow from "zustand/shallow";

export const useSelectedStore = (useStore, selectors) => useStore(
  state => {
    const result = {};

    for (const selector of selectors) {
      result[selector] = state[selector]
    }

    return result;
  },
  shallow
);