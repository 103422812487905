import { Grid, GridItem, VStack } from '@chakra-ui/react';

import { MapView } from './components/MapView';
import { Home } from './components/Home';
import { useClearRouteDirection } from './utils/hooks/useClearRouteDirection';

export default function App() {
  useClearRouteDirection();

  return (
    <Grid
      templateColumns={['1fr', null, '2fr 5fr']}
    >
      <GridItem
        top='75vh'
        gridRow={[2, null, 1]}
        h={['auto', null, '100vh']}
      >
        <VStack
          alignItems='flex-start'
          maxH='100vh'
          mb='1rem'
        >
          <Home />
        </VStack>
      </GridItem>
      <GridItem
        gridRow={[1, null, 1]}
        h={['60vh', null, '100vh']}
      >
        <MapView />
      </GridItem>
    </Grid>
  );
}
