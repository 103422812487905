import { ListItem, SimpleGrid, Box, Flex, Text } from '@chakra-ui/react';
import { RealTimeIndicator } from './RealTimeIndicator';

export function Departure({departureTime, stopName, highlight, noOp, predicted}) {
  return (
    <ListItem
      cursor={noOp ? 'inherit' : 'pointer'}
      _hover={noOp ? null : {
        bgColor: 'gray.100'
      }}
      p='0.75rem 1rem'
      listStyleType='none'
      borderTop='1px'
      borderBottom='1px'
      borderColor='gray.100'
      fontWeight={highlight ? 600 : 'inherit'}
    >
      <SimpleGrid gridTemplateColumns='auto 1fr' spacing={5}>
        <Flex>
          <Text>
            {departureTime}
          </Text>
          {predicted ? <RealTimeIndicator ml='0.25rem' /> : null}
        </Flex>
        <Box>
          <Text fontSize="sm">{stopName}</Text>
        </Box>
      </SimpleGrid>
    </ListItem>
  );
}