import { Flex, Text } from '@chakra-ui/react'

import { BusNumber } from './BusNumber'
import { Loader } from './Loader'

export function BusNumberBlock({number, isLoading, title, info}) {
  return (
    <Flex p='1rem' alignItems='center' w='100%'>
      <BusNumber number={number} />
      <Flex direction='column' ml={2}>
        { isLoading ? <Loader size='sm' />
        :
          <>
            <Text fontSize='sm' fontWeight='600'>{title}</Text>
            <Text fontSize='xs'>{info}</Text>
          </>
        }
      </Flex>
    </Flex>
  )
}