import { Flex, Box, Text, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IoBus } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { BusNumber } from "./BusNumber";

export function StopListItem(props) {
  const { t } = useTranslation();
  const Wrapper = ({children}) => props.isLink ? <Link to={`${props.id}`}>{children}</Link> : <>{children}</>;
  
  return (
    <Wrapper>
      <Flex
        w='100%'
        alignItems='center'
        cursor={props.isLink ? 'pointer' : 'initial'}
        _hover={props.isLink ? {backgroundColor: 'gray.100'} : null}
        m='0.5rem 0'
        p='1rem'
      >
        <Box mr={2}>
          <Icon as={IoBus} w={6} h={6} color='gray.700' />
        </Box>
        <Flex direction='column' w='100%'>
          <Text fontSize='md' fontWeight={500}>{props.name}</Text>
          <Text
            fontSize='sm'
            color='gray.600'
          >
            {t('route.directionEnd', { directionEnd: props.towards })}
          </Text>
          <Flex wrap='wrap'>
            {props.routes?.map((route, ind) => <BusNumber number={route?.number} key={`${ind}.${route?.number}`} mr={1} mt={1} />)}
          </Flex>
        </Flex>
      </Flex>
      </Wrapper>
  );
}