import { Icon, Text, Flex } from "@chakra-ui/react";

export function MenuGroup({children, icon, text, ...props}) {
  return (
    <Flex
      justifyContent='start'
      alignItems='center'
      {...props}
    >
      <Icon as={icon} w={5} h={5} mr={2} />
      <Flex direction='column'>
        <Text fontWeight='semibold'>{text}</Text>
        {children}
      </Flex>
    </Flex>
  )
}