import { getISODay, format } from 'date-fns';

export function getDayOfWeek(date = new Date()) {
  return getISODay(date);
}

export function timeToInt(value) {
  let timeStr;

  if (value instanceof Date) {
    timeStr = format(value, 'HH:mm:ss');
  } else {
    timeStr = value;
  }

  const [hh, mm, ss = 0] = timeStr.split(':').map(i => parseInt(i, 10));
  return hh * 3600 + mm * 60 + ss;
}

export function getDepartureTime(departureClockTimeValue, options = {}) {
  const departureTimeInSeconds = timeToInt(departureClockTimeValue);
  const nowInSeconds = timeToInt(new Date());
  const departsIn = departureTimeInSeconds - nowInSeconds;

  if (departsIn > 20 * 60 || options.renderScheduleValue || options.tomorrow) {
    return departureClockTimeValue.substring(0, 5);
  }

  if (departsIn < 60) {
    return 'Dabar';
  } else {
    const result = Math.round(departsIn / 60)
    return `${result} min`;
  }
}