import create from "zustand";
import config from "../config/config";

export const useMapStore = create(set => ({
  userLocation: null,
  map: null,
  defaultZoom: 13,
  track: [], // Array<Array<{lat, lng}>>
  trackStops: [], // Array<{lat, lng, name}>
  center: config.mapCenter,
  setUserLocation: (userLocation) => set({ userLocation }),
  setMap: (map) => set({ map }),
  setTrack: (newTrack) => set({ track: newTrack }),
  setTrackStops: (stops) => set({ trackStops: stops }),
  clearTrack: () => set({track: [], trackStops: []}),
}));
