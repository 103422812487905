import { BusStopMarker } from "../components/BusStopMarker";

export function getStopMarker(stop) {
  return (
    <BusStopMarker
      key={stop.id}
      stop={stop}
    /> 
  );
}
