import { Flex, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

export function BreadcrumbLink({to, value}) {
  return (
    <Link to={to}>
      <Flex alignItems='center'>
        <Text color='blackAlpha.800' fontWeight='semibold'>{value}</Text>
        <ChevronRightIcon />
      </Flex>
    </Link>
  );
}