export const map = 'map';
export const track = 'track';
export const trackStops = 'trackStops';
export const center = 'center';
export const userLocation = 'userLocation';
export const setMap = 'setMap';
export const setTrack = 'setTrack';
export const setUserLocation = 'setUserLocation';
export const setTrackStops = 'setTrackStops';
export const clearTrack = 'clearTrack';
export const defaultZoom = 'defaultZoom';
export const defaultCenter = 'defaultCenter';

export const routeId = 'routeId';
export const routeNumber = 'routeNumber';
export const directionId = 'directionId';
export const directionName = 'directionName';
export const directionStops = 'directionStops';
export const directionEnd = 'directionEnd';
export const setRouteDirection = 'setRouteDirection';
export const clearRouteDirection = 'clearRouteDirection';
export const selectedStop = 'selectedStop';
export const setSelectedStop = 'setSelectedStop';
export const clearSelectedStop = 'clearSelectedStop';