import { VehicleMarker } from "../components/VehicleMarker";

export function getVehiclePositionMarker(item) {
  return (
    <VehicleMarker
      key={item.vehicle_id}
      number={item.displayLineNumber}
      angle={item.angle}
      lat={item.latitude}
      lng={item.longitude}
    />
  );
}
