import { InputGroup, InputRightElement, Input } from "@chakra-ui/react"
import { CloseIcon } from "@chakra-ui/icons"

export function SearchQueryInput(props) {
  return (
    <InputGroup p='0 1rem'>
      <InputRightElement
        children={
          <CloseIcon
            onClick={props.handleClearInput}
            cursor='pointer'
            color='gray.600'
            w={2}
            h={2}
            mr='1rem'
          />
        }
      />
      <Input
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.handleChange}
      />
    </InputGroup>
  )
}