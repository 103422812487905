import L from 'leaflet';
import { Marker } from "react-leaflet";
import ReactDOMServer from 'react-dom/server';

export function VehicleMarker(props) {
  L.divIcon({ })
  const icon = L.divIcon({
    html: ReactDOMServer.renderToString(<VehicleMarkerIcon angle={props.angle} number={props.number} />), // leaflet expects a HTML element in string format
    className: '', // otherwise leaflet has a default divicon styles (white rectangular background)
    iconSize: [23, 33.066],
    iconAnchor: [11.5, 16.533]
  });

  return (
    <Marker
      icon={icon}
      position={{lat: props.lat, lng: props.lng}}
    />
  );
}


function VehicleMarkerIcon(props) {
  let numberFontSize = 12;
  let numberTopPosition = 10;

  if (props.number?.length >= 2) {
    numberTopPosition = 12;
  }
  
  if (props.number?.length === 2) {
    numberFontSize = 10;
  } else if (props.number?.length > 2) {
    numberFontSize = 8;
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      transform: `rotate(${props.angle}deg)`,
      maxWidth: '20px',
      maxHeight: '28.753px',
    }}>
      <p style={{
        transform: `rotate(-${props.angle}deg)`,
        fontSize: `${numberFontSize}px`,
        position: 'absolute',
        top: `${numberTopPosition}px`,
        color: 'white',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        zIndex: 100000,
      }}>{props.number}</p>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        width='20'
        height='28.753'
        viewBox='0 0 20 28.753'
      >
        <g transform='matrix(-0.79 0 0 -0.82 10 14.39)'>
          <path style={{
              stroke: 'none',
              strokeWidth: 1,
              strokeDasharray: 'none',
              strokeLinecap: 'butt',
              strokeDashoffset: 0,
              strokeLinejoin: 'miter',
              strokeMiterlimit: 4,
              fill: '#319795',
              fillRule: 'nonzero',
              opacity: 1,
            }}
            vectorEffect='non-scaling-stroke'
            transform='translate(-11.95, -17.44)'
            d='M 11.95 0 C 5.3507299999999995 0 0 5.22957 0 11.67938 C 0 17.431910000000002 9.45293 31.55175 11.54872 34.64591 C 11.727129999999999 34.95096 12.172979999999999 34.95096 12.351289999999999 34.64591 C 14.446959999999999 31.551750000000002 23.900009999999998 17.431900000000002 23.900009999999998 11.679380000000002 C 23.900009999999998 5.229570000000002 18.54927 1.7763568394002505e-15 11.950009999999999 1.7763568394002505e-15 z'
            strokeLinecap='round'
          />
        </g>
      </svg>
    </div>
  );
}
