import { useMediaQuery } from '@chakra-ui/react';
import { MapContainer, TileLayer } from 'react-leaflet';
// eslint-disable-next-line no-unused-vars
import "react-leaflet-fullscreen/dist/styles.css";

import { useSelectedStore } from '../store/useSelectedStore';
import { stores } from '../store/stores';
import * as selectors from '../store/selectors';
import { useMapEffects } from '../utils/hooks/useMapEffects';
import { MapRef } from './MapRef';

export function Map({children}) {
  const [mobileUser] = useMediaQuery('(max-width: 768px)');
  const { center } = useSelectedStore(
    stores.map,
    [
      selectors.center,
      selectors.setMap,
    ]
  );
  useMapEffects();

  return (
    <MapContainer
      fullscreenControl={mobileUser}
      style={{
        width: '100%',
        height: '100%',
      }}
      center={center}
      zoom={13}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {children}
      <MapRef />
    </MapContainer>
  );
}
