import { useLocation } from "react-router-dom";
import { useRealTimeVehiclesQuery, useRouteQuery } from "../../services/api/queries";

export function useVehiclesPositions() {
  const location = useLocation();
  const routesLocation = location.pathname.match(/routes\/\d+/);
  const routeId = routesLocation?.[0].split('/')[1];
  const { data: route } = useRouteQuery(routeId);
  const { data: vehiclesPositions } = useRealTimeVehiclesQuery();

  if (!vehiclesPositions) {
    return null;
  }

  if (routeId && route) {
    return vehiclesPositions.filter(i => i.displayLineNumber && i.displayLineNumber === route[0].number);
  }

  return vehiclesPositions;
}
