import { Flex } from '@chakra-ui/react';

export function BusNumber({number, width, height, ...props}) {
  return (
    <Flex
      borderRadius='5px'
      alignItems='center'
      justifyContent='center'
      minW={width || '2rem'}
      maxW='4rem'
      p='0.25rem'
      h={height || '2rem'}
      bgColor='teal.500'
      color='white'
      fontWeight={600}
      {...props}
    >
      {number}
    </Flex>
  )
}