import { List } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { Departure } from './Departure';
import { Loader } from './Loader';

import { useSelectedStore } from '../store/useSelectedStore';
import { stores } from '../store/stores';
import * as selectors from '../store/selectors';
import { useRealTimeTripQuery, useTripQuery } from '../services/api/queries';
import { useRehydrateRoute } from '../utils/hooks/useRehydrateRoute';

export function Trip() {
  const params = useParams();
  const { data, isLoading } = useTripQuery(params.tripId);
  const { data: tripRealTime, isLoading: tripRealTimeIsLoading } = useRealTimeTripQuery(params.tripId);
  const {selectedStop, directionStops} = useSelectedStore(stores.route, [
    selectors.directionStops,
    selectors.selectedStop,
  ]);

  useRehydrateRoute();

  const tripTimeline = [];

  if (!isLoading && !tripRealTimeIsLoading) {
    data?.forEach((stop, ind) => {
      const { name } = directionStops?.find(i => i.id === stop.stopId) || {};
      const highlight = stop.stopId === selectedStop?.id;
      let time = stop.departureTime;
      let predicted = false;

      if (tripRealTime) {
        const stopInTrip = tripRealTime?.find(j => j.stopId === stop.stopId);

        if (stopInTrip) {
          if (stopInTrip.actualTime) {
            time = stopInTrip.actualTime.substring(0, 5);
          } else if (stopInTrip.predictedTime) {
            predicted = true;
            time = stopInTrip.predictedTime.substring(0, 5);
          }
        }
      }

      tripTimeline.push(
        <Departure
          key={`${stop.stopId}-${ind}`}
          departureTime={time}
          predicted={predicted}
          stopName={name}
          highlight={highlight}
          noOp={true}
        />
      );
      
    })
  }

  if (isLoading) {
    return <Loader size='lg' />
  }

  return (
    <List overflowY='scroll'>
      {tripTimeline}
    </List>
  );
}
