import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, VStack, Text } from '@chakra-ui/react';
import { IoBus } from 'react-icons/io5';

import { MenuGroup } from './MenuGroup';
import { Loader } from './Loader';
import { BusRouteSelection } from './BusRouteSelection';
import { SearchQueryInput } from './SeachQueryInput';

export function BusRoutes(props) {
  const { t } = useTranslation();
  const [routeSearchInput, setRouteSearchInput] = useState('');
  const handleChange = (event) => setRouteSearchInput(event.target.value);
  const handleClearInput = () => setRouteSearchInput('');

  let routes = props.routes.data;

  if (routeSearchInput) {
    const regex = new RegExp(routeSearchInput, 'i');
    routes = routes?.filter(i => regex.test(i.name));
  }

  routes = routes?.reduce((result, current) => {
    const keyName = current.serviceGroupId;

    if (!result[keyName]) {
      result[keyName] = { name: current.serviceGroupName, data: [] };
    }

    result[keyName].data.push(<BusRouteSelection key={current.id} route={current} />);

    return result;
  }, {});

  if (props.routes.isError) {
    return <Text>{t('error')}</Text>;
  }

  return (
    <VStack
      w='100%'
      overflowY='scroll'
      alignItems='flex-start'
      maxH='100vh'
    >
      <MenuGroup
        p='1rem'
        icon={IoBus}
        text={t('routes')}
      />
      <Box w='100%'>
        <SearchQueryInput
          handleChange={handleChange}
          handleClearInput={handleClearInput}
          value={routeSearchInput}
          placeholder={t('routes.search.placeholder')}
        />
      </Box>
      <Box w='100%' direction='column' overflow='scroll'>
        {props.routes.isLoading
          ? <Loader size='lg' />
          : Object.entries(routes)
            .sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
            .map(([index, {name, data}]) => (
                <Box key={index}>
                  <Box p='1rem' bgColor='gray.50'>
                    <Text fontWeight={500}>{name}</Text>
                  </Box>
                  <Box>{data}</Box>
                </Box>
              )
            )
        }
      </Box>
    </VStack>
  );
}