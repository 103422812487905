import { useMap } from 'react-leaflet';
import { useEffect } from 'react';

import * as selectors from '../store/selectors';
import { useSelectedStore } from '../store/useSelectedStore';
import { stores } from '../store/stores'

export function MapRef() {
  const mapInstance = useMap();
  const { setMap, map } = useSelectedStore(stores.map, [selectors.setMap, selectors.map]);

  useEffect(() => {
    if (!map) {
      setMap(mapInstance);
    }
  }, [setMap, mapInstance, map]);
  
  return null;
}
