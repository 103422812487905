import { Flex, Spinner } from '@chakra-ui/react';

export function Loader({size, h}) {
  return (
    <Flex justifyContent='center' alignItems='center' h={h}>
      <Spinner
        color='teal.500'
        size={size}
      />
    </Flex>
  );
}