import { Box, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { BusNumber } from "./BusNumber";

export function BusRouteSelection({children, route, href, ...props}) {
  return (
    <Link to={href || `${route?.id}`}>
      <Flex
        p='1rem'
        alignItems='center'
        _hover={{
          bgColor: 'gray.100'
        }}
        {...props}
      >
        <BusNumber number={route?.number} mr='1rem' />
        <Box lineHeight='1rem'>
          <Text lineHeight='1.5rem'>
            {route?.name}
          </Text>
        </Box>
        {children}
      </Flex>
    </Link>
  );
}