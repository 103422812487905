import { useQuery } from 'react-query';

const schedulesBase = '/schedules';
const realTimeBase = '/realtime';

const routesPath = `${schedulesBase}/routes`;
const departuresPath = `${schedulesBase}/departures`;
const stopsPath = `${schedulesBase}/stops`;

export const useRoutesQuery = () => useQuery(routesPath);

export const useRouteQuery = (routeId) => useQuery(`${routesPath}/${routeId}`, { enabled: !!routeId});

export const useDirectionStopsQuery = (d, s) => useQuery(`${departuresPath}/direction/${d}/stop/${s}`);

export const useTripQuery = (tripId) => useQuery(`${departuresPath}/trip/${tripId}`, { enabled: !!tripId });

export const useSearchStopsQuery = (query, enabled = false, merge = false) => {
  let q = query;

  if (merge) {
    q += '&m=1';
  }

  return useQuery(`${stopsPath}?q=${q}`, { enabled });
}

export const useStopQuery = (stopId) => useQuery(`${stopsPath}/${stopId}`, {enabled: !!stopId, refetchOnWindowFocus: true, refetchInterval: 10 * 1000 });

export const useBoundsStopsQuery = (bounds) => useQuery(`${stopsPath}?bounds=${bounds}`, { enabled: !!bounds });

const realTimeDeparturesPath = `${realTimeBase}/departures`;
const realTimeVehiclesPath = `${realTimeBase}/vehicles`;

export const useRealTimeStopDeparturesQuery = (d, s) => useQuery(`${realTimeDeparturesPath}/direction/${d}/stop/${s}`, { refetchOnWindowFocus: true, refetchInterval: 10 * 1000 });

export const useRealTimeTripQuery = (tripId) => useQuery(`${realTimeDeparturesPath}/trip/${tripId}`, { enabled: !!tripId });

export const useRealTimeVehiclesQuery = () => useQuery(realTimeVehiclesPath, { refetchOnWindowFocus: true, refetchInterval: 5 * 1000 });